import React, { useState } from 'react';
import axios from 'axios';

function PDFToImage() {
  const [file, setFile] = useState(null);
  const [converting, setConverting] = useState(false);
  const [results, setResults] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) return;

    setConverting(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://docify.top/api/pdf-to-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setResults(response.data);
    } catch (error) {
      console.error('Conversion error:', error);
    } finally {
      setConverting(false);
    }
  };

  return (
    <div className="container">
      <div className="tool-page">
        <h1>Convert PDF to Image</h1>
        <form onSubmit={handleSubmit}>
          <div className="file-input-container">
            <input
              type="file"
              id="file-input"
              className="file-input"
              accept=".pdf"
              onChange={handleFileChange}
            />
            <label htmlFor="file-input" className="file-label">
              Choose PDF File
            </label>
            <span className="file-info">
              {file ? file.name : 'No file selected'}
            </span>
          </div>
          <button
            type="submit"
            className="convert-button"
            disabled={!file || converting}
          >
            {converting ? 'Converting...' : 'Convert to Image'}
          </button>
        </form>
        {results && (
  <div className="results">
    {results.images.map((image, index) => (
      <div key={index} className="result-item">
        <h3>Page {index + 1}</h3>
        <a href={`https://docify.top${image.path}`} target="_blank" rel="noopener noreferrer" className="thumbnail-link">
          <img src={`https://docify.top${image.thumbnail}`} alt={`Thumbnail ${index + 1}`} />
        </a>
        <a href={`https://docify.top${image.path}`} download className="download-button">
          Download Image
        </a>
      </div>
    ))}
  </div>
)}
      </div>
    </div>
  );
}

export default PDFToImage;