import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setActiveDropdown(null);
  };

  const toggleDropdown = (index, e) => {
    e.preventDefault();
    if (window.innerWidth <= 768) {
      setActiveDropdown(activeDropdown === index ? null : index);
    }
  };

  useEffect(() => {
    const closeMenuOnResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
        setActiveDropdown(null);
      }
    };

    window.addEventListener('resize', closeMenuOnResize);
    return () => window.removeEventListener('resize', closeMenuOnResize);
  }, []);

  return (
    <header className="header">
      <div className="container">
        <nav>
          <Link to="/" className="nav-logo">
            <div className="logo-icon">
              <span className="logo-letter">D</span>
              <div className="logo-circle"></div>
            </div>
            <span className="logo-text">ocify</span>
          </Link>
          <ul className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
            <li className="nav-item"><Link to="/" onClick={toggleMenu}>Home</Link></li>
            <li className="nav-item">
              <a href="#" onClick={(e) => toggleDropdown(0, e)} className="dropdown-toggle">
                Convert PDF
              </a>
              <ul className={`dropdown ${activeDropdown === 0 ? 'show' : ''}`}>
                <li><Link to="/pdf-to-doc" onClick={toggleMenu}>PDF to DOC</Link></li>
                <li><Link to="/pdf-to-excel" onClick={toggleMenu}>PDF to Excel</Link></li>
                <li><Link to="/pdf-to-image" onClick={toggleMenu}>PDF to Image</Link></li>
                <li><Link to="/pdf-to-pptx" onClick={toggleMenu}>PDF to PowerPoint</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="#" onClick={(e) => toggleDropdown(1, e)} className="dropdown-toggle">
                Convert Document
              </a>
              <ul className={`dropdown ${activeDropdown === 1 ? 'show' : ''}`}>
                <li><Link to="/doc-to-pdf" onClick={toggleMenu}>DOC/DOCX to PDF</Link></li>
                <li><Link to="/excel-to-pdf" onClick={toggleMenu}>Excel to PDF</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <a href="#" onClick={(e) => toggleDropdown(2, e)} className="dropdown-toggle">
                Other Conversions
              </a>
              <ul className={`dropdown ${activeDropdown === 2 ? 'show' : ''}`}>
                <li><Link to="/image-to-pdf" onClick={toggleMenu}>Image to PDF</Link></li>
                <li><Link to="/csv-to-json" onClick={toggleMenu}>CSV to JSON</Link></li>
                <li><Link to="/markdown-to-html" onClick={toggleMenu}>Markdown to HTML</Link></li>
                <li><Link to="/html-to-pdf" onClick={toggleMenu}>HTML to PDF</Link></li>
              </ul>
            </li>
            <li className="nav-item"><Link to="/about" onClick={toggleMenu}>About</Link></li>
            <li className="nav-item"><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
          </ul>
          <div className={`hamburger ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;