import React, { useState } from 'react';
import axios from 'axios';
import PDFIcon from '../components/PDFIcon';

function ImageToPDF() {
  const [files, setFiles] = useState([]);
  const [converting, setConverting] = useState(false);
  const [result, setResult] = useState(null);

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (files.length === 0) return;

    setConverting(true);
    const formData = new FormData();
    files.forEach((file) => formData.append('images', file));

    try {
      const response = await axios.post('https://docify.top/api/image-to-pdf', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setResult(response.data);
    } catch (error) {
      console.error('Conversion error:', error);
    } finally {
      setConverting(false);
    }
  };

  return (
    <div className="container">
      <div className="tool-page">
        <h1>Convert Images to PDF</h1>
        <form onSubmit={handleSubmit}>
          <div className="file-input-container">
            <input
              type="file"
              id="file-input"
              className="file-input"
              accept="image/*"
              multiple
              onChange={handleFileChange}
            />
            <label htmlFor="file-input" className="file-label">
              Choose Images
            </label>
            <span className="file-info">
              {files.length > 0 ? `${files.length} file(s) selected` : 'No files selected'}
            </span>
          </div>
          <button
            type="submit"
            className="convert-button"
            disabled={files.length === 0 || converting}
          >
            {converting ? 'Converting...' : 'Convert to PDF'}
          </button>
        </form>
        {result && (
          <div className="result">
            <h2>Conversion Complete!</h2>
            <div className="result-item">
              <PDFIcon width={80} height={80} />
              <a 
                href={`https://docify.top${result.pdf}`} 
                download 
                className="download-button"
              >
                Download PDF
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageToPDF;