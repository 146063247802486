import React, { useState } from 'react';
import axios from 'axios';
import { DocIcon, DocxIcon } from './icons';

function PDFToDoc() {
  const [file, setFile] = useState(null);
  const [format, setFormat] = useState('docx');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setError(null);
    setDownloadLink(null);
  };

  const handleFormatChange = (e) => {
    setFormat(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError('Please select a file');
      return;
    }

    setLoading(true);
    setError(null);
    setDownloadLink(null);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('format', format);

    try {
      const response = await axios.post('https://docify.top/api/python/pdf-to-doc', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadLink(url);
    } catch (error) {
      setError('An error occurred during conversion. Please try again.');
      console.error('Conversion error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="tool-page">
      <h1>PDF to DOC/DOCX Converter</h1>
      <form onSubmit={handleSubmit}>
        <div className="file-input-container">
          <input
            type="file"
            id="file-input"
            className="file-input"
            onChange={handleFileChange}
            accept=".pdf"
          />
          <label htmlFor="file-input" className="file-label">
            Choose PDF
          </label>
          <span className="file-info">
            {file ? file.name : 'No file selected'}
          </span>
        </div>
        <div className="format-selection">
          <label>
            <input
              type="radio"
              value="docx"
              checked={format === 'docx'}
              onChange={handleFormatChange}
            />
            DOCX
          </label>
          <label>
            <input
              type="radio"
              value="doc"
              checked={format === 'doc'}
              onChange={handleFormatChange}
            />
            DOC
          </label>
        </div>
        <button 
          type="submit" 
          className="convert-button"
          disabled={!file || loading}
        >
          {loading ? 'Converting...' : 'Convert'}
        </button>
      </form>
      {error && <p className="error">{error}</p>}
      {downloadLink && (
        <div className="result">
          <h2>Conversion Complete</h2>
          <div className="result-item">
            {format === 'doc' ? <DocIcon /> : <DocxIcon />}
            <a href={downloadLink} download={`converted.${format}`} className="download-button">
              Download {format.toUpperCase()} File
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default PDFToDoc;