import React, { useState } from 'react';
import axios from 'axios';

function ExcelToPdf() {
  const [file, setFile] = useState(null);
  const [converting, setConverting] = useState(false);
  const [result, setResult] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) return;

    setConverting(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://docify.top/api/python/excel-to-pdf', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      setResult(url);
    } catch (error) {
      console.error('Conversion error:', error);
    } finally {
      setConverting(false);
    }
  };

  return (
    <div className="container">
      <div className="tool-page">
        <h1>Convert Excel to PDF</h1>
        <form onSubmit={handleSubmit}>
          <div className="file-input-container">
            <input
              type="file"
              id="file-input"
              className="file-input"
              accept=".xls,.xlsx"
              onChange={handleFileChange}
            />
            <label htmlFor="file-input" className="file-label">
              Choose Excel File
            </label>
            <span className="file-info">
              {file ? file.name : 'No file selected'}
            </span>
          </div>
          <button
            type="submit"
            className="convert-button"
            disabled={!file || converting}
          >
            {converting ? 'Converting...' : 'Convert to PDF'}
          </button>
        </form>
        {result && (
          <div className="results">
            <a href={result} download="converted.pdf" className="download-button">
              Download PDF
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default ExcelToPdf;