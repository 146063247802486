import React, { useState } from 'react';
import axios from 'axios';
import { PDFIcon } from './icons';

function DocToPDF() {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && (selectedFile.name.endsWith('.doc') || selectedFile.name.endsWith('.docx'))) {
      setFile(selectedFile);
      setError(null);
      setDownloadLink(null);
    } else {
      setFile(null);
      setError('Please select a valid DOC or DOCX file.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      setError('Please select a file');
      return;
    }

    setLoading(true);
    setError(null);
    setDownloadLink(null);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://docify.top/api/python/doc-to-pdf', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadLink(url);
    } catch (error) {
      setError('An error occurred during conversion. Please try again.');
      console.error('Conversion error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="tool-page">
      <h1>DOC/DOCX to PDF Converter</h1>
      <form onSubmit={handleSubmit}>
        <div className="file-input-container">
          <input
            type="file"
            id="file-input"
            className="file-input"
            onChange={handleFileChange}
            accept=".doc,.docx"
          />
          <label htmlFor="file-input" className="file-label">
            Choose DOC/DOCX
          </label>
          <span className="file-info">
            {file ? file.name : 'No file selected'}
          </span>
        </div>
        <button 
          type="submit" 
          className="convert-button"
          disabled={!file || loading}
        >
          {loading ? 'Converting...' : 'Convert to PDF'}
        </button>
      </form>
      {error && <p className="error">{error}</p>}
      {downloadLink && (
        <div className="result">
          <h2>Conversion Complete</h2>
          <div className="result-item">
            <PDFIcon />
            <a href={downloadLink} download="converted.pdf" className="download-button">
              Download PDF File
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default DocToPDF;