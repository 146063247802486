import React, { useState } from 'react';
import axios from 'axios';

function HtmlToPdf() {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState('');
  const [converting, setConverting] = useState(false);
  const [result, setResult] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setUrl('');
  };

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setFile(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file && !url) return;

    setConverting(true);
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    } else {
      formData.append('url', url);
    }

    try {
      // Set the User-Agent header for the request
      const headers = {
        'Content-Type': 'multipart/form-data',
        'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3'
      };

      const response = await axios.post('https://docify.top/api/python/html-to-pdf', formData, {
        headers: headers,
        responseType: 'blob'
      });

      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      setResult(blobUrl);
    } catch (error) {
      console.error('Conversion error:', error);
    } finally {
      setConverting(false);
    }
  };

  return (
    <div className="container">
      <div className="tool-page">
        <h1>Convert HTML to PDF</h1>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <input
              type="url"
              placeholder="Enter URL"
              value={url}
              onChange={handleUrlChange}
              className="url-input"
            />
            <p className="or-text">OR</p>
            <div className="file-input-container">
              <input
                type="file"
                id="file-input"
                className="file-input"
                accept=".html,.htm"
                onChange={handleFileChange}
              />
              <label htmlFor="file-input" className="file-label">
                Choose HTML File
              </label>
              <span className="file-info">
                {file ? file.name : 'No file selected'}
              </span>
            </div>
          </div>
          <button
            type="submit"
            className="convert-button"
            disabled={(!file && !url) || converting}
          >
            {converting ? 'Converting...' : 'Convert to PDF'}
          </button>
        </form>
        {result && (
          <div className="results">
            <a href={result} download="converted.pdf" className="download-button">
              Download PDF
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default HtmlToPdf;