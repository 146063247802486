import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="home">
      <div className="hero">
        <h1>Transform Your Documents with Ease</h1>
        <p>Fast, secure, and free document conversion tools at your fingertips</p>
      </div>
      <div className="container">
        <div className="tools">
          <Link to="/pdf-to-image" className="tool-card" style={{background: 'linear-gradient(135deg, #f6d365 0%, #fda085 100%)'}}>
            <svg className="tool-card-icon" viewBox="0 0 24 24">
              <path d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M11,13V15H9V13H11M11,9V11H9V9H11M11,17V19H9V17H11Z" />
            </svg>
            <h2>PDF to Image</h2>
            <p>Convert PDF files to high-quality images in seconds</p>
          </Link>
          <Link to="/image-to-pdf" className="tool-card" style={{background: 'linear-gradient(135deg, #84fab0 0%, #8fd3f4 100%)'}}>
            <svg className="tool-card-icon" viewBox="0 0 24 24">
              <path d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M8,13.91C6.28,13.91 2,15.28 2,17V18H14V17C14,15.28 9.72,13.91 8,13.91M8,12C9.11,12 10,11.11 10,10C10,8.89 9.11,8 8,8C6.89,8 6,8.89 6,10C6,11.11 6.89,12 8,12Z" />
            </svg>
            <h2>Image to PDF</h2>
            <p>Create professional PDF files from your images effortlessly</p>
          </Link>
          <Link to="/pdf-to-doc" className="tool-card" style={{background: 'linear-gradient(135deg, #FF9A8B 0%, #FF6A88 100%)'}}>
            <svg className="tool-card-icon" viewBox="0 0 24 24">
              <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20M9.5,11C9.22,11 9,11.22 9,11.5V13H15V11.5C15,11.22 14.78,11 14.5,11H9.5M9,14.5V16H15V14.5C15,14.22 14.78,14 14.5,14H9.5C9.22,14 9,14.22 9,14.5Z" />
            </svg>
            <h2>PDF to DOC/DOCX</h2>
            <p>Convert PDF files to editable DOC or DOCX documents</p>
          </Link>
          <Link to="/doc-to-pdf" className="tool-card" style={{background: 'linear-gradient(135deg, #a1c4fd 0%, #c2e9fb 100%)'}}>
            <svg className="tool-card-icon" viewBox="0 0 24 24">
              <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20M13,13V15H15V17H13V19H11V17H9V15H11V13H13Z" />
            </svg>
            <h2>DOC/DOCX to PDF</h2>
            <p>Convert your Word documents to PDF format easily</p>
          </Link>
          <Link to="/pdf-to-excel" className="tool-card" style={{background: 'linear-gradient(135deg, #67B26F 0%, #4ca2cd 100%)'}}>
            <svg className="tool-card-icon" viewBox="0 0 24 24">
              <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20M12,19L8,15H10.5V12H13.5V15H16L12,19Z" />
            </svg>
            <h2>PDF to Excel</h2>
            <p>Convert PDF files to editable Excel spreadsheets</p>
          </Link>
          <Link to="/pdf-to-pptx" className="tool-card" style={{background: 'linear-gradient(135deg, #FF9A9E 0%, #FECFEF 100%)'}}>
            <svg className="tool-card-icon" viewBox="0 0 24 24">
              <path d="M2,3H4V21H2V3M20,3V21H22V3H20M6,3H18V21H6V3M8,5V19H16V5H8Z" />
            </svg>
            <h2>PDF to PowerPoint</h2>
            <p>Transform PDF files into editable PowerPoint presentations</p>
          </Link>
          <Link to="/excel-to-pdf" className="tool-card" style={{background: 'linear-gradient(135deg, #43E97B 0%, #38F9D7 100%)'}}>
            <svg className="tool-card-icon" viewBox="0 0 24 24">
              <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20M12,19L8,15H10.5V12H13.5V15H16L12,19Z" />
            </svg>
            <h2>Excel to PDF</h2>
            <p>Convert Excel spreadsheets to PDF format with ease</p>
          </Link>
          <Link to="/csv-to-json" className="tool-card" style={{background: 'linear-gradient(135deg, #F4D03F 0%, #16A085 100%)'}}>
            <svg className="tool-card-icon" viewBox="0 0 24 24">
              <path d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20M10,13H8V11H10V13M14,13H12V11H14V13M18,13H16V11H18V13Z" />
            </svg>
            <h2>CSV to JSON</h2>
            <p>Convert CSV files to JSON format quickly and accurately</p>
          </Link>
          <Link to="/markdown-to-html" className="tool-card" style={{background: 'linear-gradient(135deg, #C33764 0%, #1D2671 100%)'}}>
            <svg className="tool-card-icon" viewBox="0 0 24 24">
              <path d="M20.56 18H3.44C2.65 18 2 17.37 2 16.59V7.41C2 6.63 2.65 6 3.44 6H20.56C21.35 6 22 6.63 22 7.41V16.59C22 17.37 21.35 18 20.56 18M6.81 15.19V11.53L8.73 13.88L10.65 11.53V15.19H12.58V8.81H10.65L8.73 11.16L6.81 8.81H4.89V15.19H6.81M19.69 12H17.77V8.81H15.85V12H13.92L16.81 15.28L19.69 12Z" />
            </svg>
            <h2>Markdown to HTML</h2>
            <p>Convert Markdown files to HTML format effortlessly</p>
          </Link>
          <Link to="/html-to-pdf" className="tool-card" style={{background: 'linear-gradient(135deg, #FFA17F 0%, #00223E 100%)'}}>
            <svg className="tool-card-icon" viewBox="0 0 24 24">
              <path d="M12,17.56L16.07,16.43L16.62,10.33H9.38L9.2,8.3H16.8L17,6.31H7L7.56,12.32H14.45L14.22,14.9L12,15.5L9.78,14.9L9.64,13.24H7.64L7.93,16.43L12,17.56M4.07,3H19.93L18.5,19.2L12,21L5.5,19.2L4.07,3Z" />
            </svg>
            <h2>HTML to PDF</h2>
            <p>Convert HTML files to PDF format with precision</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;