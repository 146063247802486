import React from 'react';

function Footer() {
  return (
    <footer>
      <p>&copy; 2024 Docify. All rights reserved.</p>
    </footer>
  );
}

export default Footer;